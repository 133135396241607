.mainContainer{
    width: 100%;
}
.mainContent{
    width: 100%;
    display: inline-flex;

}
.menuWrapper{
    width: 25%;
    padding: 0px 1% 0px 0px;
    margin: 0px 2% 0px 0px;
    max-height: 60vh;
    overflow-y: scroll;
}
.contentWrapper{
    width: 68%;
    padding: 20px 2%;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.selectedContent{
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.selectedTitle{
    font-weight: 600;
}
.selectedTitle h2{
    margin: 0px;
    color: #000000;
}
.selectedTitle h3{
    color: #00000086;
    padding: 4px 0px 10px;
    margin: 0px;
}
.baseContainer{
    width: 100%;
    padding: 4px 0px 12px 0px;
}
.baseContainer h5{
    margin: 0px;
    color: #00000086;
    text-transform: uppercase;
}
.baseContainer p{
    color: #00000080;
    padding: 0px;
    margin: 0px;
}

.eyeMakeupContainer{
    width: 100%;
    padding: 4px 0px 12px 0px;
}
.eyeMakeupContainer h5{
    margin: 0px 0px 4px 0px;
    color: #00000086;
    text-transform: uppercase;
}
.eyeMakeupContainer ul{
    margin: 0px;
    color: #00000080;
} 
.whatIncludedContainer{
    width: 100%;
    padding: 4px 0px 12px 0px;
}
.whatIncludedContainer h5{
    margin: 0px 0px 4px 0px;
    color: #00000086;
    text-transform: uppercase;
}
.whatIncludedContainer ul{
    margin: 0px;
    color: #00000080;
}



.actionWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 10px 0px;
    align-items: center;
}

.actionIcon{
    margin-right: 10px;
    border: 1px solid green;
    border-radius: 6px;
    padding: 6px 8px;
    background-color: rgba(151, 241, 151, 0.199);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.bookDemo{
    padding: 11px 20px;
    margin-right: 10px;
    border: none;
    /* background-color: #dfdfdf; */
    background-color: transparent;
    font-weight: 600;
    font-size: 0.8rem;
    color: #00000094;
}
.bookNow{
    padding: 11px 20px;
    background-color: #da1884;
    border: none;
    color: white;
    font-weight: 600;
}



@media only screen and (max-width: 450px) {
    .mainContent{
        width: 100%;
        flex-direction: column;
    }
    .menuWrapper{
        width: 100%;
        padding: 25px 1% 0px 0px;
        max-height: fit-content;
    }
    
    .contentWrapper{
        display: none;
    }
}