.mainContainer{
    width: 100%;
    background-image: url("https://lokaci.s3.ap-south-1.amazonaws.com/dotted-map.png");
    background-repeat: no-repeat;
}
.mainContainer img{
    height: 100%;
}

.contentWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    height: 60vh;
}
.eachCol{
    width: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.eachLocation{
    display: inline-flex;
    align-items: center;
    width: 33%;
}
.colUpper{
    width: 100%;
}
.colUpper h2{
    padding: 0px;
    margin: 0px 0px 20px;
}
.colUpper p{
    padding: 0px;
    margin: 0px;
    color: #00000086;
}
.colLower{
    padding: 40px 0px;
}



@media only screen and (max-width: 450px) {
    .eachCol{
        width: auto;
        padding: 0px 4%;
    }
}