.mainContainer {
    width: 100%;
}

.firstImpressionWrapper {
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    position: relative;
    overflow: hidden;
}

.heroImageContainer {
    width: 100%;
}

.heroImageContainer img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.heroContentWrapper {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-image: linear-gradient(180deg, black, #00000032, #00000052, #00000032, black);
}


.bodyTransWrapper {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    height: 80vh;
    align-items: center;
}

.logoContent {
    margin: 0px !important;
    color: white;
    text-align: center;
}

.offSliders {
    color: white
}

.offSliders .ofslidsP {
    font-size: 4rem;
}

.ofslidsP {
    display: none;
    position: relative;
    padding: 0px 0px;
    margin: 0px;
    font-weight: 500
}



.subCaption {
    font-size: 1.4rem;
    padding: 10px
}

.searchBarContainer input {
    padding: 14px 20px;
    border-radius: 30px;
    border: 2px solid #fffffff2;
    font-family: 'Lato';
    margin: 50px;
    width: 320px;
    background: #0000005e;
    color: white;
}

.searchBarContainer input:focus {
    outline: none;
}

.searchBarContainer input::placeholder {
    color: white;
}

.whatNextWrapper{
    height: 80vh;
    width: 86%;
    padding: 10px 7%;
    display: inline-flex;
    align-items: center;
    color: #00000082;
}
.wnhTitle{
    font-size: 4rem;
    padding: 0px 0px 20px;
}
.wnhVal{
    font-size: 1.2rem;
}
.buildingItForFuture {
    font-size: 1.4rem;
    font-weight: 600;
    color: black;
    opacity: 0.4;
    padding: 19px 0px;
}

@media only screen and (max-width: 450px) {
    .bodyTransWrapper{
        height: 100vh;
        width: 90%;
        padding: 0px 5%;
    }
    .logoContent{
        width: 100%;
    }
    .offSliders .ofslidsP{
        font-size: 2rem;
        font-weight: 600;
    }
    .searchBarContainer{
        width: 80%;
        padding: 40px 10% 0px;
    }
    .searchBarContainer input{
        width: calc(100% - 40px);
        margin: 0px;
    }
    .subCaption{
        font-size: 1rem;
    }
    .heroImageContainer img{
        object-fit: cover;
    }



    .whatNextWrapper{
        height: 60vh;
    }
    .wnhTitle {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .wnhVal{
        font-size: 1.1rem;
    }
    .buildingItForFuture{
        font-size: 1rem;
    }

}