.mainWrapper{
    width: 100%;
    background-image: linear-gradient(#00000086, transparent, transparent, white, white);
}
.mainContainer{
    width: 90%;
    padding: 10% 5%;
}

.mainContent{
    background-color: white;
    width: calc(100% - 40px);
    padding: 20px;
    text-align: justify;
    box-shadow:  0px 2px 3px rgb(0 0 0 / 25%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow-x: hidden;
}


@media only screen and (max-width: 450px) {
    .mainWrapper{
        width: 100%;
        background-color: #00000086;
    }
    .mainContainer{
        width: 94%;
        padding: 20% 3%;
    }
}