.mainContainer{
    width: 100%;
    height: 100vh;
    position: relative;
}
.mainBg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
.mainBg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mainContent{
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: linear-gradient(#000000, #00000061, #0000005e, #00000085);
    height: 100%;
    width: 100%;
    color: white;
}
.mainContentWrapper{
    width: 100%;
    position: relative;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mainContentWrapper h1{
    font-weight: 600;
}
.mainContentWrapper h2{
    font-size: 200px;
    margin: 0px;
}
.goToHomeBtn{
    background-color: #ffcb08;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


@media only screen and (max-width: 450px) {
    .mainContentWrapper h2{
        font-size: 100px;
    }
    .mainContentWrapper h1{
        text-align: center;
    }

}