.galleryContainer {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.galleryFooter {
    display: inline-flex;
    justify-content: center;
    width: 100%;
}

.galleryFooter button {
    padding: 10px 26px;
    font-weight: 600;
    border: none;
    background-color: #F0F3C4;
    margin: 10px 0px 40px;
}




@media only screen and (max-width: 450px) {

    .galleryContent {
        width: 100%;
    }
    .eachGroup{
        width: 100%;
    }
    
    


}