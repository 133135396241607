.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 86%;
    margin: 7%;
    box-shadow: 0px 3px 30px rgb(0 0 0 / 10%);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.mainContent{
    width: 94%;
    display: inline-flex;
    justify-content: space-between;
    padding: 25px 3%;
}
.leftContainer{
    width: 30%;
}
.rightContainer{
    width: 70%;
}
.introducing{
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
}
.lokaciBridalMakeup{
    color: #da1884;
    padding: 16px 0px;
}
.lokaciBridalMakeup h3{
    margin: 0px;
    font-size: 2rem;

}
.description{
    color: #00000082;
    font-size: 0.9rem;
}
.exploreBtnWrapper{
    padding: 30px 0px 10px;
}
.exploreBtn{
    background-color: #da1884;
    padding: 10px 20px;
    font-weight: 600;
}
.exploreBtn:hover{
    background-color: #000000;
    color: white;
}

.cardWrapper{
    width: 90%;
    padding: 0px 0px 0px 10%;
    display: inline-flex;
    justify-content: space-between;
}
.eachCard{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px 3px 30px rgb(0 0 0 / 10%);
    width: 30%;
    margin: 0px 1.5%;
    height: 35vh;
    overflow: hidden;
}
.cardUpper{
    width: 100%;
    height: 70%;
}
.cardUpper img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.cardUpper video{
    object-fit: cover;
    height: 100%;
    width: 100%;
}   
.cardTitle{
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    padding: 4px 2%;
    font-weight: 600;
    color: #00000082;
}
.cardSubTitle{
    font-size: 0.7rem;
    color: #00000065;
    padding: 4px 2%;
}


@media only screen and (max-width: 450px) {
    .mainContainer{
        width: 90%;
        margin: 5%;
    }
    .mainContent{
        flex-direction: column;
    }
    .leftContainer{
        width: 100%;
    }
    .rightContainer{
        width: 100%;
    }
    .cardWrapper{
        flex-direction: column;
        padding: 20px 0px 0px;
        width: 100%;
    }
    .eachCard{
        width: 100%;
        margin: 10px 0px 0px 0px;
    }
}