.mainContainer {
    width: 100%;
    background-color: black;
    color: #ffffff;
}

.mainContentContainer {
    display: inline-flex;
    justify-content: space-between;
    width: 90%;
    padding: 20px 5%;
}

.leftContainer {
    width: 25%;
}

.smallLogo img {
    height: 50px;
}

.madeWithLove {
    color: #ffffffda;
    font-size: 0.7rem;
    padding: 4px 0px;
}

.eachCompound {
    width: 100%;
}

.compoundTitle {
    width: 100%;
}

.compoundTitle h3 {
    margin: 20px 0px 0px;
    font-weight: 600;
    color: #ffffffda;
}

.compoundContent {
    width: 100%;
}

.compoundContent p {
    margin: 0px;
    text-align: justify;
    font-size: 0.8rem;
    color: #ffffffda;
}

.eachCol {
    width: 33%;
}

.colTitle h4 {
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.eachLink {
    font-size: 1rem;
    font-weight: 600;
    color: #ffffffda;
    padding: 0px 0px 6px 0px;
}




.rightContainer {
    width: 65%;
    padding: 0px 0px 0px 10%;
    display: inline-flex;
    justify-content: space-between;
}

a {
    color: white;
}

.copyNPrivacyWrapper {
    padding: 40px 5% 20px;
    display: inline-flex;
    justify-content: space-between;
    width: 90%;
}

.privacyWrapper {
    display: inline-flex;

}

.eachPrivacyUrl {
    padding: 0px 0px 0px 40px;
}



@media only screen and (max-width: 450px) {
    .mainContentContainer {
        flex-direction: column;
    }

    .leftContainer {
        width: 100%;
    }

    .rightContainer {
        flex-direction: column;
        width: 100%;
        padding: 0px;
    }

    .eachCol {
        width: 100%;
    }

    .colTitle h4 {
        margin: 20px 0px 4px 0px;
    }

    .eachLink {
        font-size: 0.9rem;
        font-weight: 400;
        color: #ffffffc9;
        padding: 0px 0px 6px 0px;
    }

    .copyNPrivacyWrapper {
        flex-direction: column;
        padding: 20px 5%;
        width: 90%;
    }

    .privacyWrapper {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        padding: 10px 0px;
    }

    .eachPrivacyUrl {
        padding: 0px;

    }
}