.mainWrapper{
    width: 90%;
    background-color: #F0F9FF;
    padding: 50px 5%;
}   

.mainContainer{
    background-color: white;
    padding: 25px 2%;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    border: 1px solid #a8a8a85d;

    display: inline-flex;
    width: calc(96% - 2px);
}
.titleSection{
    width: 35%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
}
.titleCaption{
    width: 100%;
}
.titleCaption p{
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.3px;
    color: #00000086;
}
.titleCaption h2{
    padding: 0px;
    margin: 0px;
    font-weight: 600;
}
.titleSubCaption{
    width: 70%;
}
.titleSubCaption h4{
    font-weight: 400;
    color: #00000086;
    padding: 0px;
    margin: 0px;
}
.exploreBtnWrapper{
    width: 100%;
}
.exploreBtnWrapper button{
    border: none;
    color: black;
    font-weight: 500;
    padding: 10px 20px;
    margin: 10px 0px;
    background-color: #F0F3C4;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    -ms-border-radius: 34px;
    -o-border-radius: 34px;
    box-shadow:  0px 1px 7px rgb(0 0 0 / 21%);
}
.cardSection{
    width: 65%;
    display: inline-flex;
    justify-content: space-between;
    padding: 0px 0px;
}
.eachCardWrapper{
    width: 31%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
    height: 320px;

    /* border: 1px solid #dfdfdf; */
    box-shadow: 0px 2px 3px rgb(0 0 0 / 25%);
}
.eachCardUpper{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.eachCardUpper img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.cardBigTitle{
    width: 100%;
}
.cardBigTitle h4{
    padding: 0px;
    margin: 0px;
}
.cardSmallTitle{
    width: 100%;
}
.cardSmallTitle p{
    padding: 0px;
    margin: 0px;
    color: #00000086;
    font-size: 0.8rem;
    text-align: justify;
}
.eachCardLower{
    padding: 10px 3%;
}




@media only screen and (max-width: 450px) {
    .mainContainer{
        flex-direction: column;
    }
    .titleSection{
        width: 100%;
    }
    .titleSubCaption{
        width: 100%;
    }
    .cardSection{
        width: 100%;
        flex-direction: column;
    }
    .eachCardWrapper{
        width: 100%;
        margin-bottom: 10px;
    }
}