.mainModal{
    width: 100%;
    z-index: 100000000;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    display: none;

    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

    box-shadow: 0px 2px 3px rgb(0 0 0 / 25%);

}
.modalContent{
    margin: 10%;
    width: 80%;
    background-color: white;
    height: 65%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    position: fixed;
    bottom: 0px;

    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
}

.sentOnLine{
    display: inline-flex;
    padding: 20px 0px;
    justify-content: center;
    width: 100%;
}
.sentOnLineTxt{
    color: #00000086;
}
.changeNumber{
    cursor: pointer;
    color: black;
    padding: 0px 6px;
    font-weight: 600;
}
.enterOtpWrapper{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}
.enterOtpCaption{
    padding: 10px 0px;
    font-weight: 600;
}
.havenotReceivedWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: 20px 0px;
    flex-direction: column;
    align-items: center;
}
.submitOtpWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0px;
}
.submitButton{
    padding: 7px 24px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    background-color: #F0F3C4;
    border: 1px solid rgba(65, 65, 65, 0.521);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.submitBtnDull{
    padding: 7px 24px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
    background-color: #f0f3c42c;
    border: 1px solid rgba(65, 65, 65, 0.521);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: #00000066;
}
.resendOtpBtn{
    border: none;
    background-color: white;
    font-weight: 600;
    padding: 10px 0px;
}

.enterPhoneWrapper{
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.enterPhoneContainer{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
}
.eachInputWrapper{
    width: 100%;
    position: relative;
}
.label{
    position: absolute;
    left: 4%;
    background-color: white;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: 400;
    color: #00000066;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    background-color: #f3f3f3;
}
.eachInputWrapper input{
    padding: 15px 2% 11px;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    letter-spacing: 1.4px;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: #b6b6b650;
    margin: 13px 0px;
}
.enterPhoneContainer input::placeholder{
    letter-spacing: normal;
}
.enterPhoneContainer input:focus{
    outline: none;
}
.enterPhoneContainer button{
    padding: 7px 22px;
    margin: 10px;
    background-color: #F0F3C4;
    border: 1px solid #dfdfdf;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cancel{
    text-align: center;
    color: rgba(255, 0, 0, 0.884);
    font-size: 12px;
    cursor: pointer;
}
.cancel:hover{
    font-weight: 600;
}
.resendOtpTxt{
    font-size: 12px;
    font-weight: 600;
    color: #00000086;
}

.verificationWrapper{
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.verifiedMessage{
    text-align: center;
}
.otpEnterContainer{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.beingVerifiedTxt{
    font-size: 13px;
    color: #0084ff;
    font-weight: 600;
}

.invalidOtpMessage{
    text-align: center;
    color: red;
    font-style: italic;
    padding: 8px 0px 8px;
    font-size: 11px;
    font-weight: 600;
}
.otpExpiredTxt{
    font-size: 13px;
    color: red;
    font-weight: 600;
}
.otpExpiredTxt p{
    text-align: center;
}
.otpExpiredTxt h6{
    text-align: center;
}



@media only screen and (max-width: 450px) {
    .modalContent{
        width: 100%;
        padding: 0px;
        margin: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
    }

    .enterPhoneContainer{
        width: 80%;
    }

    .enterPhoneContainer input{
        padding: 13px 2%;
        width: calc(96% - 2px);
        border: 1px solid #dfdfdf;
        letter-spacing: 1.4px;
        text-align: center;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        background-color: #b6b6b650;
    }
    .sentOnLine{
        width: 80%;
        padding: 0px 10% 10px;
        flex-direction: column;
        text-align: center;

    }
    .verifiedMessage{
        padding: 0px 10%;
        text-align: center;
    }


}