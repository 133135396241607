.whatNextWrapper{
    height: 80vh;
    width: 86%;
    padding: 10px 7%;
    display: inline-flex;
    align-items: center;
    color: #00000082;
}
.wnhTitle{
    font-size: 4rem;
    padding: 0px 0px 20px;
}
.wnhVal{
    font-size: 1.2rem;
}
.buildingItForFuture {
    font-size: 1.4rem;
    font-weight: 600;
    color: black;
    opacity: 0.4;
    padding: 19px 0px;
}

@media only screen and (max-width: 450px) {
    .whatNextWrapper {
        height: 60vh;
    }

    .wnhTitle {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .wnhVal {
        font-size: 1.1rem;
    }

    .buildingItForFuture {
        font-size: 1rem;
    }
}