.mainContainer{
    width: 90%;
    background-color: aliceblue;
    padding: 30px 5%;
}
.title{
    width: 100%;
}
.title h6{
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding: 0px;
    margin: 0px;
}
.content{
    width: 100%;
}
.content p{
    color: #00000086;
}