.mainContainer{
    /* background-color: rgba(228, 228, 228, 0.308); */
    background-color: white;
    width: calc(100% - 32px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 10px 15px;

    min-height: 140px;
    max-height: 140px;

    /* align-items: center; */
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    font-size: 0.7rem;
    font-weight: 600;
    text-align: justify;
}
.reviewMainTxt h4{
    padding: 0px;
    margin: 0px;
}
.reviewer{
    text-align: right;
    color: #00000086;
}

.lowerSection{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.starRatingContainer{
    display: inline-flex;
    padding: 14px 0px 4px;
}