.mainContainer{
    width: 90%;
    padding: 30px 5%;
    background-image: linear-gradient(#cde2cbaf, #cde2cb5e, #cde2cbaf);
}
.mainHeader{
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    text-align: left;
}
.mainCaptionIcon{
    width: 7%;
}
.mainCaption{
    width: 93%;
}
.mainCaption h2{
    padding: 0px;
    margin: 0px;
}
.mainCaption p{
    padding: 0px;
    margin: 0px;
    color: #00000086;
}
.uspWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 0px;
    flex-direction: row;
}
.eachUspWrapper{
    width: 33%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.eachUspIcon{
    padding-right: 15px;
}
.eachUspTxtBold{
    font-weight: 700;
}
.eachUspTxtReg{
    color: #00000086;
}
.reviewCardsWrapper{
    width: 100%;
    display: inline-flex;
}
.eachReviewCardWrapper{
    width: 30%;
    padding: 0px 3%;
}

@media only screen and (max-width: 450px) {
    .mainHeader{
        flex-direction: column;
        text-align: center;
    }
    .mainCaption{
        width: 100%;
    }
    .uspWrapper{
        flex-direction: column;
    }
    .eachUspWrapper{
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        padding: 10px 0px;
    }
    .eachUspIcon{
        width: 15%;
    }
    .eachUspTxt{
        width: 85%;
    }
    .reviewCardsWrapper{
        flex-direction: column;
    }
    .eachReviewCardWrapper{
        width: 94%;
        padding-bottom: 30px;
    }
}