.eachNavOption{
    margin-bottom: 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    overflow: hidden;
}
.eachNavOptionSelected{
    margin-bottom: 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #F0F3C4;
    overflow: hidden;
}
.menuTitleWrapper{
    display: inline-flex;
    justify-content: space-between;
    width: 92%;
    cursor: pointer;
    align-items: center;
    background-color: white;
    padding: 12px 4%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.menuTitleWrapperSelected{
    display: inline-flex;
    justify-content: space-between;
    width: 92%;
    cursor: pointer;
    align-items: center;
    background-color: #da18844f;
    padding: 12px 4%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

}
.navTitle{
    font-weight: 600;
}
.typesWrapper{
    background-color: white;
    padding: 0px 0px 10px 5%;
}
.eachTypeWrapper{
    width: 100%;
    cursor: pointer;
    padding: 5px 0px;
    color: #00000086;
}
.eachTypeWrapper:hover{
    font-weight: 600;
}
.eachTypeWrapperSelected{
    width: 100%;
    cursor: pointer;
    padding: 5px 0px;
    color: #da1884;
    font-weight: 600;
}


.mobileViewDetails{
    /* background-color: #dfdfdf42; */
    border-bottom: 1px solid #dfdfdfcc;
    width: 94%;
    padding-bottom: 5px;
    display: none;
}

.actionWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 20px 0px 4px;
}
.bookDemo{
    padding: 3px 6px;
    margin: 0px 4px 0px 0px;
    background-color: #dfdfdf75;
    border: 1px solid #dfdfdf;
}
.bookNow{
    padding: 3px 6px;
    background-color: #da1884;
    border: 1px solid #da1884;
    color: white;
}
.actionIcon{
    padding-right: 8px;
}


@media only screen and (max-width: 450px) {
    .mobileViewDetails{
        display: block;
    }
    .eachTypeWrapperSelected{
        display: none;
    }
    .eachTypeWrapper{
        display: none;

    }
    .title{
        font-weight: 600;
        padding: 10px 0px 4px;
        color: #da1884;
    }
}
