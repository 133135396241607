.mainContainer{
    width: 100%;
    height: 100vh;
    background-color: #f1f1f1;
}
.mainContent{
    width: 60%;
    padding: 10vh 20%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.fieldsWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
}

.eachInputField{
    width: 40%;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 5px;
}
.eifTitle{
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: #f1f1f1;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    font-size: 10px;
}
.eifField input{
    padding: 15px 3% 10px;
    width: calc(94% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eifField input:focus{
    outline: none;
}