.mainContainer {
    background-color: #47474783;
    height: calc(1080px/2.5);
    width: calc(720px/2.5);
    border: 2px solid #ffffff86;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    margin: 0px 0px 30px 0px;
    overflow: hidden;
}

.mainContainer img {
    object-fit: cover;
    height: 100%;
}

.mainContainerL {
    background-color: #47474783;
    height: calc(720px/4);
    width: calc(720px/2.5);
    border: 2px solid #ffffff86;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    margin: 0px 0px 30px 0px;
    overflow: hidden;
    object-fit: contain;
}

.mainContainerL img {
    width: 100%;
}

.flipCardInner{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.3s;
    transform-style: preserve-3d;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
}

.mainContainerL:hover .flipCardInner {
    cursor: pointer;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}
.mainContainer:hover .flipCardInner {
    cursor: pointer;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
}

.flipCardFront {
    background-color: #bbb;
    color: black;
}

.flipCardBack {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
}




@media only screen and (max-width: 450px) {
    .mainContainer{
        width: 100%;
        height: 420px;
        
    }
    .mainContainer img{
        width: 100%;
    }
    .flipCardFront{
        background-color: black;
    }
    .mainContainerL{
        width: 100%;
        height: 240px;
    }
}