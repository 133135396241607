.mainWrapper {
    width: 100%;
    position: relative;
}

.heroVideoWrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 0;
}

.heroVideoContainer {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
}

.heroVideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.heroVideoLayer {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(#000000, transparent, transparent, #000000);
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
}

.bookDemoBtn {
    margin-bottom: 7vh;
    background-color: #EFF3C4;
    border: none;
    padding: 12px 20px;
    color: black;
    font-weight: 600;
}




.whatWeAreOffering {
    background-color: white;
}

.wwaoContainer {
    background-color: #EFF3C4;
    padding-bottom: 60px;
}

.wwaoTitle {
    width: 90%;
    padding: 20px 5%;
    display: inline-flex;
    align-items: center;
}

.wwaoTitleTxt {
    padding-right: 4%;
}

.offeringOptions {
    display: inline-flex;
}

.eachOfferingOptionBtn {
    font-weight: 700;
    padding: 6px 20px;
    cursor: pointer;
    border-bottom: 2px solid #000000;
}

.eachOfferingOptionBtnSelected {
    font-weight: 700;
    padding: 6px 20px;
    cursor: pointer;
    color: #da1884;
    border-bottom: 2px solid #da1884;
}

.wwaoTitle h4 {
    padding: 0px;
    margin: 0px;
}

.wwaoContent {
    width: 90%;
    padding: 0px 5%;

}
.thumbnailImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.eachWwaoContent {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.eachOption {
    margin: 10px 0px;
    border: 1px solid rgba(173, 173, 173, 0.651);
    padding: 10px 10px;
    width: 45%;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #f4f4f482;
}

.expertiseTxt {
    padding: 10px 0px;
    color: #00000086;
    text-align: justify;

}

.askTheExpertBtn {
    background-color: black;
    color: white;
    border: none;
    padding: 9px 14px;
}



.galleryWrapper{
    background-color: #000000;
    color: white;
}

.galleryTitle {
    width: 90%;
    padding: 20px 5%;
    display: inline-flex;
    align-items: center;
}

.galleryTitleTxt {
    width: 20%;
}

.galleryOptions {
    display: inline-flex;
    width: 70%;
    align-items: baseline;
}
.eachGalleryOptionBtn{
    font-weight: 700;
    padding: 14px 20px;
    cursor: pointer;
    border-bottom: 2px solid #ffffff;
}
.eachGalleryOptionBtnSelected {
    font-weight: 700;
    padding: 14px 20px;
    cursor: pointer;
    color: #da1884;
    border-bottom: 2px solid #da1884;
}
.scrollOptionsNav {
    display: inline-flex;
    width: 10%;
    justify-content: flex-end;
}

.eachScrollNavHandler {
    padding: 8px 10px;
    background: #F0F3C4;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
}

.eachScrollBtn {
    cursor: pointer;
}

.scrollingMenu {
    overflow: auto;
    white-space: nowrap;
    display: inline-flex;
    width: 100%;
    scroll-behavior: smooth;
}

.scrollingMenu::-webkit-scrollbar {
    /* width: 10px; */
    background-color: transparent;
}

.scrollingMenu::-webkit-scrollbar-track {
    background: transparent;
}

.scrollingMenu::-webkit-scrollbar-thumb {
    background: transparent;
}

.scrollingMenu::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

.galleryContentWrapper {
    /* height: 100vh; */
    width: 90%;
    padding: 10px 5%;
    
}
.reasonsWrapper{
    width: 100%;
}
.reasonsContainer{
    width: 90%;
    padding: 30px 5%;
}
.reasonsHeader{
    width: 100%;
}
.reasonsHeader h1{
    font-weight: 600;
    color: #00000086;
    text-align: center;
}
.reasonIntro{
    width: 100%;
    color: #00000086;
}
.reasonIntro p{
    text-align: justify;
}
.boldTxt{
    font-weight: 600;
}
.uspContainer{
    width: 86%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 7%;
}
.eachUspWrapper{
    width: 42.5%;
    padding: 20px 1.25%;
    border: 1px solid #ffffff;
}
.eachUspWrapper:hover{
    border: 1px solid #dfdfdf;
}
.eachUspWrapper h3{
    color: #00000086;
    margin: 0px 0px 0px 0px;
}
.eachUspWrapper p{
    color: #00000076;
    text-align: justify;
    margin: 4px 0px 0px 0px;
    font-size: 0.8rem;
}




@media only screen and (max-width: 450px) {
    .wwaoTitle{
        flex-direction: column;
        padding: 0px 5% 10px;
        text-align: center;
    }
    .wwaoTitleFixed{
        position: fixed;
        background-color: #f0f3c4;
        top: 0px;
        left: 0px;
        width: 100%;
    }
    .wwaoTitleTxt{
        padding: 0px 5%;
    }
    .wwaoTitleTxt h2 {
        padding-bottom: 0px;
        margin-bottom: 4px;
    }
    .wwaoTitleFixed .wwaoTitleTxt h2{
        font-size: 14px;
        color: #00000086;
    }
    .wwaoTitleFixed .offeringOptions{
        width: 100%;
        justify-content: space-evenly;
    }
    .eachOfferingOptionBtn{
        font-size: 12px;
    }
    .eachOfferingOptionBtnSelected{
        font-size: 12px;
    }


    .galleryTitle{
        width: 100%;
        flex-direction: column;
        padding: 4px 0%;
    }
    .galleryTitleTxt{
        width: 100%;
    }
    .galleryTitleTxt h2{
        text-align: center;
        margin-bottom: 2px;
    }
    .galleryOptions{
        width: 90%;
    }
    .scrollOptionsNav{
        display: none;
    }




    .reasonsHeader h1{
        font-size: 1.4rem;
    }



    .uspContainer{
        flex-direction: column;
    }
    .eachUspWrapper{
        width: 100%;
    }
}
