.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 65%;
    padding: 20px 17.5%;
}
.mainHeader{
    text-align: center;
    font-size: 1.4rem;
}
.stillHaveQuestionWrapper{
    width: 100%;
    color: #00000086;
}
.stillHaveQuestionWrapper h4{
    font-weight: 500;
    text-align: center;
    margin: 30px 0px 0px;
}
.stillHaveQuestionWrapper p{
    padding: 0px 20%;
    text-align: center;
    margin: 10px 0px 20px;
    color: #00000065;
}



@media only screen and (max-width: 450px) {
    .mainContainer{
        width: 90%;
        padding: 20px 5%;
    }
    .mainHeader h3{ 
        font-size: 1rem;
    }
    .stillHaveQuestionWrapper p{
        padding: 0px 10px;
    }

}