.headerTransWrapper {
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 16px 5%;
    z-index: 9999999;
    position: absolute;

}

a {
    text-decoration: none;
}

.htwLeft {
    width: 20%;
}

.htwCenter {
    width: 60%;
}

.htwRight {
    width: 20%;
    text-align: right;
}

.mainLogo {
    width: 100%;
}

.mainLogo img {
    width: 160px;
}

.menuWrapper {
    width: 50%;
    display: inline-flex;
    justify-content: space-around;
    padding: 0px 25%;
}

.menuWrapper a {
    color: white;
    text-decoration: none;
}

.eachMenu {
    text-transform: uppercase;
    letter-spacing: 1.23px;
    font-weight: 700;
    position: relative;

}

.loginBtn {
    background-color: white;
    color: black;
    text-transform: uppercase;
    font-weight: 800;
    padding: 4px 20px;
    border-radius: 5px;
    letter-spacing: 1.4px;
}

.iconWrapper {
    position: absolute;
    right: 0px;
    top: -20px;
    width: 100%;
    text-align: center;
}

.iconWrapper img {
    transform: rotate(-0deg);
    height: 20px;
    background-color: rgba(255, 0, 0, 0);
    padding: 2px 10px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    -webkit-transform: rotate(-0deg);
    -moz-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    -o-transform: rotate(-0deg);
}



@media only screen and (max-width: 450px) {
    .htwLeft {
        width: 50%;
    }

    .htwCenter {
        display: none;
    }

    .htwRight {
        width: 50%;
    }

    .loginBtn {
        border: none;
    }

    .mainLogo img {
        height: 30px;
        width: auto;
    }
}