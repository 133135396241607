.mainContainer{
    width: 100%;
}
.mainContent{
    width: 86%;
    padding: 40px 7%;
}
.sliderWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    overflow-x: scroll;
}
.eachSliderWrapper{
    min-width: 35%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    margin: 0px 3% 0px 0px;
}
.eachSliderWrapper:last-child{
    margin: 0px;
}
.eachSliderWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 450px) {
    .mainContent{
        padding: 10px 4%;
        width: 92%;
    }
    .eachSliderWrapper{
        min-width: 100%;
    }
}