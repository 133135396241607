.mainContainer{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.optionTitle{
    width: 100%;
}
.optionTitle h2{
    padding: 0px;
    margin: 0px;
}
.eachOptionWrapper{
    width: 40%;
    background-color: #ffffff;
    padding: 20px 2%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
}
.optionTitle h2{
    padding: 0px;
    margin: 0px;
}

.actionWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0px 0px;
}


.eachField{
    width: 100%;
    padding: 0px 0px 10px;
}
.eachField h5{
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    color: #00000086;
}
.eachField p{
    padding: 0px;
    margin: 0px;
    color: #00000080;
}
.eachField ul{
    margin: 0px;
    color: #00000080;
}
.phoneActionBtn{
    background-color: rgba(151, 241, 151, 0.199);
    border: 1px solid green;
    padding: 5px 6px;
    margin: 0px 10px 0px 0px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.bookDemoBtn{
    padding: 7px 14px;
    margin: 0px 0px 0px 10px;
    background-color: white;
    border: 1px solid #dfdfdf;
}
.bookNowBtn{
    padding: 7px 14px;
    margin: 0px 0px 0px 10px;
    background-color: #da1884;
    border: 1px solid #da1884;
    color: white;
}
.bookNowBtn:hover{
    font-weight: 600;
}




@media only screen and (max-width: 450px) {
    .mainContainer{
        flex-direction: column;
    }
    .eachOptionWrapper{
        width: 92%;
        margin: 10px 0px 0px;
        padding: 14px 4%;
    }
    .actionWrapper{
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .shareableActions{
        width: 30%;
    }
    .performableActions{
        width: 70%;
    }
    .performableActions button{
        font-size: 0.7rem;
    }
}