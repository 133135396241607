.eachFaqWrapper{
    width: 97%;
    border: 1px solid #dfdfdf;
    padding: 8px 1.5%;
    margin: 0px 0px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.efHeader{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.efHeader h5{
    padding: 0px;
    margin: 8px 0px;
    font-size: 1rem;
    font-weight: 600;
}
.efHeader:hover{
    font-weight: 700;
    color: #00000086;
}

.efContent{
    width: 100%;
    text-align: justify;
}

.efContent p{
    padding: 0px;
    margin: 0px 0px 0px;
    color: #00000086;
    font-size: 0.9rem;
}

@media only screen and (max-width: 450px) {
    .efHeader h5{
        font-size: 0.8rem;
    }
    .efContent p{
        font-size: 0.7rem;
    }
}