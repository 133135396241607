.mainWrapper{
    width: 100%;
    /* background-color: #DAE0E4; */
    scroll-behavior: smooth;
}
.mainContainer{
    width: 100%;
}
.heroVideoWrapper{
    width: 100%;
    height: 100%;
    position: relative;
}
.videoWrapper{
    height: 100vh;

}
.videoWrapper video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.videoCoverWrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#000000, #00000050, #00000050, #00000086, #000000);
}
.videoCoverContainer{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    height: 100%;
    flex-direction: column;
}


.upperPartCover{
    height: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border: 1px solid pink; */
}
.upperPartCover h2{
    margin: auto 0px 0px 0px;
    text-transform: uppercase;
    font-size: 42px;
}
.lowerPartCover{
    height: 50%;
    width: 86%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7%;
    /* border: 1px solid red; */
}

.eachCardWrapper{
    
    background-color: #000000b0;
    width: 30%;
    cursor: pointer;
    color: white;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 14px 1%;
    border: 1px solid #ffffff65;

}
.eachCardWrapper:hover{
    background-color: #F66E3D;
}
.cardTitle{
    font-weight: 600;
}
.cardContent{
    color: #ffffffb7;
    font-weight: 400;
    font-size: 0.9rem;
}
.cardContent p{
    text-align: justify;
    min-height: 80px;
}
.exploreBtn{
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}
.exploreBtnTxt{
    font-weight: 600;
    text-transform: uppercase;
    padding: 0px 10px 0px;
    letter-spacing: 1.2px;
}

.firstPaintWrapper{
    width: 100%;
    height: 100%;
    background-color: white;
}
.whatWeOfferInPackageWrapper{
    padding: 10px 7%;
    width: 86%;
    position: relative;
}
.fpwBackground{
    height: 30vh;
    background-color: black;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}
.wwoipContainer{
    width: 100%;
    position: relative;
}
.eachWwoipContainer{
    width: 95%;
    border: 1px solid #dfdfdf;
    margin: 20px 0px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    background-color: #f2f2f2;
    padding: 20px 2.5%;
}
.headerBodyWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.categoryIconWrapper{
    width: 10%;
}
.categoryIconWrapper img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    /* border: 4px solid #F66E3D; */
}
.categoryContentWrapper{
    width: 88%;
    background-color: white;
    padding: 10px 1%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.categoryContentTitle{
    font-weight: 600;
}


.longDescription{
    color: #00000086;
}
.secondDescription{
    color: #00000086;
}

.ewcFooter{
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    padding: 20px 0px 0px;
}
.scheduleBtn{
    color: #18414c;
    font-weight: 600;
    padding: 0px 20px;
}
.consultNowBtn{
    color: white;
    background-color: #18414c;
    padding: 8px 14px;
    font-weight: 600;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.howWeDoTheMagicWrapper{
    width: 86%;
    padding: 20px 7% 50px; 
    background-color: #F0F9FF;
}
.howWeDoTheMagicWrapper h2{
    text-align: center;
    margin: 40px 0px 10px;
    font-weight: 600;
}
.howWeDoTheMagicWrapper h6{
    font-weight: 500;
    margin: 0px;
    font-size: 1rem;
    color: #00000086;
}
.howWeDoTheMagicWrapper p{
    color: #00000086;
}
.howWeDoTheMagicWrapper ul{
    margin: 0px;
}
.howWeDoTheMagicWrapper li{
    padding: 5px 0px;
}



@media only screen and (max-width: 450px) {
    .lowerPartCover{
        flex-direction: column;
        height: 100%;
    }
    .eachCardWrapper{
        width: 100%;
        margin: 5px 0px;
    }

    .headerBodyWrapper{
        flex-direction: column;
    }
    .categoryIconWrapper{
        width: 100%;
    }
    .categoryContentWrapper{
        width: 95%;
        padding: 10px 2.5%;
    }
    .longDescription{
        text-align: justify;
    }
    .secondDescription{
        text-align: justify;
    }
    .ewcFooter{
        font-size: 0.8rem;
        justify-content: space-between;
    }
    .whatWeOfferInPackageWrapper{
        padding: 10px 4%;
        width: 92%;
    }
}