.mainWrapper{
    width: 100%;
    padding: 50px 0px;
}
.mainContainer{
    width: 82%;
    margin: 10px 7%;
    padding: 20px 2%;
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #dadada;
    background-color: #012F59;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: white;
}
.leftContainer{
    width: 50%;
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.leftContainer h2{
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin: 0px;
}
.titleContainer{
    padding: 40px ;
}
.titleContainer h1{
    width: 100%;
    margin: 10px 0px;
}
.contentContainer{
    padding: 40px;
    color: #ffffffd0;
}
.rightContainer{
    width: 50%;
}
.formWrapper{
    width: 80%;
    padding: 40px 10%;
    height: 60vh;
}
.formContainer{
    width: 80%;
    padding: 5% 10%;
    height: 90%;
    background-color: #F8FAFB;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.formTitle{
    width: 100%;
}
.formTitle h2{
    margin: 0px;
    width: 100%;
    text-align: left;
    color: #012F59;
}
.eachInput{
    width: 100%;
    padding: 4px 0px;
}
.eachInput input{
    padding: 12px 2%;
    width: 96%;
    border: 1px solid #dadada;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.consultingFor{
    width: 100%;
    padding: 4px 0px;
    color: black;   
}
.consultingForWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    color: #00000086;
}
.eachConsulting{
    padding: 6px 2%;
    border: 1px solid #dfdfdf;
    min-width: 20%;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
}
.eachConsultingSelected{
    padding: 6px 2%;
    border: 1px solid #dfdfdf;
    min-width: 20%;
    text-align: center;
    background-color: #012F59;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    cursor: pointer;
    color: white;

}
.eachInput label, .consultingFor label{
    color: #00000086;
    margin: 10px 4px 20px;
    font-size: 0.8rem;
}
.actionBtnWrapper{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px 0px;
}

.submitBtn{
    background-color: #ff8450;
    padding: 12px 40px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.submitBtnDisabled{
    opacity: 0.5;
    background-color: #ff8450;
    padding: 12px 40px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.callNowBtn a{
    color: #012F59;
    font-weight: 600;
}


@media only screen and (max-width: 450px) {
    .mainWrapper{
        padding: 10px 0px;
    }
    .mainContainer{
        flex-direction: column;
        width: 84%;
        margin: 10px 4%;
        padding: 15px 4%;
    }
    .leftContainer{
        width: 100%;
    }
    .titleContainer{
        padding: 0px;
        font-size: 0.8rem;
    }
    .contentContainer{
        text-align: justify;
        padding: 0px;
        font-size: 0.8rem;
    }
    .rightContainer{
        width: 100%;
    }
    .formWrapper{
        width: 90%;
        padding: 10px 5%;
    }
    .formContainer{
        width: 90%;
        padding: 5% 5%;
    }
    .formWrapper{
        width: 100%;
        padding: 10px 0px;
    }
    .formContainer{
        padding: 0% 5%;
    }
}