.sendQueryWrapper{
    background-color: aliceblue;
    padding: 50px 5%;
    width: 90%;
}
.sendQueryContainer{
    background-color: white;
    border: 1px solid #a8a8a85d;
    padding: 25px 2%;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.sendQueryContainerHeader{
    width: 100%;
    font-size: 0.7rem;
}
.sendQueryContainerHeader h2{
    padding: 0px;
    margin: 4px 0px;
}
.sendQueryContainerHeader h4{
    color: #00000086;
    font-weight: 600;
    padding: 0px;
    margin: 0px 0px 10px;
}
.sendQueryContentWrapper{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.sqcwOne{
    display: inline-flex;
    align-items: center;
    width: 70%;
    border-right: 1px solid #dfdfdf;
}
.sqcwTwo{
    width: 26%;
    padding: 10px 2%;
    display: inline-flex;
    align-items: center;
}
.sqcwoFir{
    width: 10%;
}
.sqcwoSec{
    width: 90%;
}
.sqcqoSecHeader{
    color: #00000086;
}
.queryForm{
    border: 1px solid #dfdfdf;
    width: 70%;
    margin: 10px 30% 0px 0px;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.queryForm input{
    width: calc(70% - 20px);
    padding: 13px 10px;
    border: none;
}
.queryForm button{
    width: 30%;
    padding: 13px 10px;
    border: none;
    font-weight: 600;
    border-left: 1px solid #dfdfdf;
}
.queryForm input:focus{
    outline: none;
}
.queryForm button:focus{
    outline: none;
}
.qr{
    width: 160px;
}
.qr img{
    width: 100%;
}
.sqcwtCaption{
    font-weight: 600;
}



@media only screen and (max-width: 450px) {
    .sendQueryWrapper{
        padding: 20px 5%;
    }
    .sendQueryContentWrapper{
        flex-direction: column;
        width: 100%;
    }
    .sendQueryContainer{
        padding: 18px 5%;
    }
    .sqcwOne{
        width: 100%;
        border: none;
        flex-direction: column;
    }
    .sqcwoFir{
        width: 25%;
    }
    .queryForm{
        width: 100%;
    }
    .sqcwoSec{
        width: 100%;
    }
    .sqcqoSecHeader{
        font-size: 12px;
    }
    .queryForm input{
        width: calc(60% - 20px);
    }
    .queryForm button{
        width: 40%;
    }
    .sqcwTwo{
        display: none;
        flex-direction: column;
        width: 96%;
    }


}