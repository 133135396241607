.mainContainer{
    background-color: black;
    padding: 30px 5%;
    width: 90%;
    color: #ffffff;
}
.mainContent{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.secCopyright{
    text-align: right;
    align-items: flex-end;
}