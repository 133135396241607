.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 86%;
    padding: 15vh 7% 20px;
    position: relative;
}
.backdrop{
    background-color: black;
    height: 40vh;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}
.contentWrapper{
    width: 92%;
    background-color: #f1f1f1;
    padding: 10px 4%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.mainHeader{
    justify-content: space-between;
    width: 100%;
    padding: 40px 0px;
}

.logo img{
    height: 80px;
}

.mainContent{
    width: 100%;
}
.mainContent p{
    text-align: justify;
}
.mainBigTitle{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 32px;
    font-weight: 600;
}

.offSliders {
    font-weight: 600;
}

.offSliders .ofslidsP {
    color: #da1884;
    font-weight: 600;
}
.subCaption{
    color: #000000;
}

.ofslidsP {
    display: none;
    position: relative;
    padding: 0px 0px;
    margin: 0px;
    font-weight: 500
}
.title{
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.title h2{
    margin: 0px;
    color: #00000086;
}
.statsWrapper{
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 7% 40px;
    width: 86%;
    border-bottom: 1px solid #dfdfdf;
}
.eachStats{
    padding: 20px 0px;
}
.statsVal{
    font-size: 34px;
    font-weight: 600;
}


@media only screen and (max-width: 450px) {
    .mainContainer{
        padding: 8vh 4%;
        width: 92%;
    }
    .backdrop{
        height: 50vh;        
    }
    .mainHeader{
        padding: 10px 0px;
    }
    .title h1{
        font-size: 1rem;
    }
    .logo img{
        height: 40px;
    }
    .mainBigTitle{
        flex-direction: column;
        padding: 20px 0px;
    }
    .subCaption{
        font-size: 12px;
    }
    .eachStats{
        width: 50%;
    }
    .statsVal{
        font-size: 30px;
        font-weight: 600;
    }

}