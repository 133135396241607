.mainContainer{
    background-color: #ffffff34; 
    padding: 20px 5%;
    width: 90%;  
}
.mainHeader{
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 600;
}
.mainContent{
    padding: 10px 0px 40px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
.eachPrintableItem{
    padding: 4px 0px;
    font-weight: 500;
    color: #00000065;
}


@media only screen and (max-width: 450px) {
    .mainContent{
        flex-direction: column;
    }

}