.headerTransWrapper {
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 16px 5%;
    z-index: 9999999;
    position: absolute;

}
a{
    text-decoration: none;
}
.htwLeft {
    width: 20%;
}

.htwCenter {
    width: 60%;
}

.htwRight {
    width: 20%;
    text-align: right;
}

.mainLogo {
    width: 100%;
}

.mainLogo img {
    width: 160px;
}

.menuWrapper {
    width: 50%;
    display: inline-flex;
    justify-content: space-around;
    padding: 0px 25%;
}

.menuWrapper a {
    color: white;
    text-decoration: none;
}

.eachMenu {
    text-transform: uppercase;
    letter-spacing: 1.23px;
    font-weight: 700
}

.loginBtn {
    background-color: white;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 5px;
    letter-spacing: 1.2px;
    border: none;
}
.htwRight a{
    display: none;
}

@media only screen and (max-width: 450px) {
    .headerTransWrapper{
        padding: 8px 3%;
        width: 94%;
        background-color: rgb(228, 122, 122);
        flex-direction: row;
        align-items: center;
        position: fixed;
        bottom: 0px;
        left: 0px;
    }
    .htwLeft{
        width: 40%;
    }
    .mainLogo{
        width: 100%;
    }
    .mainLogo img{
        height: 22px;
        width: auto;
    }
    .htwCenter{
        display: none;
    }
    .htwRight{
        width: 60%;
    }
    .loginBtn{
        font-size: 10px;
        border: none;
    }

}